<template>
  <div class="card card-custom gutter-b" id="candite-teacher">
    <div class="card-header">
      <div class="card-title">
        Đào tạo gia nhập giảng viên
      </div>
      <div class="card-title d-flex">
        <div>
            <el-button :disabled="loading_export" @click="exportExcel"  style="width: 121px" class="rounded text-white mr-3" size="mini" type="primary">
             <i v-bind:class="[loading_export ? 'el-icon-loading' : '']"></i>
                Xuất excel
            </el-button>
        </div>
        <div>
          <b-button @click="handleData(infoTeacher,'create')"  v-b-modal="'handle-candidate-teacher'" class="btn btn-success btn-sm">Thêm giảng viên
          </b-button>
          <FormCandidate
           :centers="centers"
           :teacher="infoTeacher"
           :trainers="trainers"
           @reload="getAllCandidate">
          </FormCandidate>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="example mb-10">
        <div class="row filter_form">
          <div class="col-xxl-3 col-md-4 ">
            <label for=""><b>Giảng viên</b></label>
            <div class="form-group w-100">
              <el-input class="shadow-sm" type="text" v-model="query.keyword" clearable
                placeholder="Nhập tên, sđt, email"></el-input>
            </div>
          </div>
          <div class="col-xxl-3 col-md-4">
            <label for=""><b>Trạng thái giảng viên</b></label>
            <div class="form-group w-100">
              <el-select class="w-100 shadow-sm" v-model="query.status" placeholder="Chọn trạng thái" collapse-tags
                clearable>
                <el-option v-for="item in statusTeacher" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="col-xxl-3 col-md-4">
            <label for=""><b>Khu vực làm việc</b></label>
            <div class="form-group w-100">
              <el-select class=" w-100 shadow-sm" v-model="query.area" collapse-tags placeholder="Chọn khu vực"
                clearable>
                <el-option v-for="item in areas" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="col-xxl-3 col-md-4">
            <label for=""><b>Trung tâm</b></label>
            <div class="form-group w-100">
              <el-select class="w-100 shadow-sm" placeholder="Chọn trung tâm" v-model="query.center" collapse-tags
                clearable filterable>
                <el-option v-for="item in centers" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </div>
          </div>

          <div class="col-xxl-3 col-md-4">
            <label for=""><b>Thời gian tạo ứng viên</b></label>
            <div class="form-group w-100 shadow-sm">
              <date-picker placeholder="Thời gian tạo" range v-model="query.date_range" format="DD-MM-YYYY"
                valueType="YYYY-MM-DD"></date-picker>
            </div>
          </div>
          <div class="col-xxl-3 col-md-4">
            <label for="">&ensp;</label>
            <div class="form-group">
              <button @click="srearchCandidate" class="btn btn-primary font-weight-bold mr-2">
                <i v-bind:class="[loading ? 'el-icon-loading' : 'el-icon-search']"></i>Tìm kiếm</button>
            </div>
          </div>
        </div>
        <div class="example-preview table-responsive">
          <table class="table table-vertical-center table-bordered" v-loading="loading" element-loading-text="Loading...">
            <thead>
              <tr style="background-color: #F8F8F8;">
                <th>Họ và tên</th>
                <th>Số điện thoại</th>
                <th>Email</th>
                <th>Trung tâm</th>
                <th>Khu vực</th>
                <th>Trạng thái giảng viên</th>
                <th>Đào tạo gia nhập</th>
                <th>Kỹ năng giảng dạy</th>
                <th>Thời gian học đào tạo</th>
                <th>Hành động</th>
              </tr>
            </thead>
            <tbody v-if="listCandidate?.length > 0">
              <tr v-for="(item, index) in listCandidate" :key="item.id">
                <td ><div class="name-teacher">{{ item.name ?? '' }}</div></td>
                <td>{{ item.phone ?? '' }}</td>
                <td>{{ item.email ?? '' }}</td>
                <td>
                  <div class="d-flex center-teacher ">
                    <div>{{ item.centers[0] ? item.centers[0].name : '' }}</div>
                    <div>
                      <el-tooltip class="item" effect="dark" placement="left-end">
                        <span slot="content">
                          <div v-for="(center_item, index) in item.centers.slice(1)" :key="index + center_item.name + center_item.id">
                            {{ center_item.name }}
                          </div>
                        </span>
                        <el-button v-if="item.centers.length > 1" class="ml-2"
                          style="padding: 2px; background-color: rgba(217, 217, 217, 1);">+{{ item.centers.length - 1 }}</el-button>
                      </el-tooltip>
                    </div>
                  </div>
                </td>
                <td><div class="area-teacher">{{ item.area }}</div></td>
                <td>
                  <span class="p-2" style="font-size: 12px;font-weight: 500; padding: 4px 2px; width: 93%;" :class="statusDefineCss[item.status_teacher]">{{
                    statusDefine[item.status_teacher] }}</span>
                </td>
                <td class="style-color">
                  <div class="status-schedule"> 
                      <div class="d-inline-flex mr-2 rounded-circle" style="width: 10px; height: 10px;" :class="status_scheduleCss[item.status_course_1]"></div>
                      <span>{{ status_schedule[item.status_course_1] }}</span>
                  </div>
                </td>
                <td>
                  <div class="status-schedule">
                    <div class="d-inline-flex mr-2 rounded-circle" style="width: 10px; height: 10px;" :class="status_scheduleCss[item.status_course_2]"></div>
                    <span>{{ status_schedule[item.status_course_2] }}</span>
                  </div>
                </td>
                <td>
                  <div v-if="item.deadline" class="deadline">
                    <div class="rounded text-center"
                      style="width: 100%; font-size: 13px; padding: 3px 2px 2px 2px; background-color: rgba(247, 169, 169, 1); color: #000;">
                      {{ item.deadline }}</div>
                  </div>
                </td>
                <td>
                  <div class="d-flex align-items-center text-center">
                    <div v-for="(value, index, array) in action" :key=index>
                      <a v-if="((value.condition_status).includes(item.status_teacher) && (value.is_teacher !== item.is_teacher))"
                       :title="`${value.title}`" @click="$bvModal.show(`${value.b_modal}`); handleData(item, `${value.name}`)" href="javascript:"
                       style="font-size: 0.7rem;"
                        :class="`btn btn-sm btn-icon ${value.style}${index !== array && ' mr-1'}`"><i :class="value.icon"></i>
                      </a>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="9" class="event-title no-data">Chưa có dữ liệu</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="position-absolute mt-3" style="right: 40px;"> Tổng số bản ghi: <b style="color: rgb(54, 153, 255);">{{
          totalData }}</b></div>
      </div>
    </div>
    <div class="edu-paginate mx-auto text-center">
      <paginate v-model="page" :page-count="last_page" :page-range="3" :margin-pages="1" :click-handler="clickCallback"
        :prev-text="'Trước'" :next-text="'Sau'" :container-class="'pagination b-pagination'" :pageLinkClass="'page-link'"
        :next-link-class="'next-link-item'" :prev-link-class="'prev-link-item'" :prev-class="'page-link'"
        :next-class="'page-link'" :page-class="'page-item'">
      </paginate>
    </div>

<!-- dialog xoá ứng viên giảng viên -->
    <DeleteCandidate 
    :teacher="infoTeacher"
    @reload="getAllCandidate">
    </DeleteCandidate>

<!-- dialog gia hạn ứng viên giảng viên -->
    <ExtendCandidate 
    :teacher="infoTeacher"
    @reload="getAllCandidate">
    </ExtendCandidate>

<!-- dialog duyệt ứng viên giảng viên -->
    <ApproveCandidate 
    :teacher="infoTeacher"
    @reload="getAllCandidate">
    </ApproveCandidate>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "../../../core/services/store/breadcrumbs.module";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { LIST_CANDIDATE_TEACHER, GET_ALL_TRAINERS, SHOW_CANDIDATE_TEACHER_BY_UUID} from '../../../core/services/store/course/classes.module';
import { GET_CENTERS } from "../../../core/services/store/center/centers.module";
import {FormCandidate,DeleteCandidate,ExtendCandidate,ApproveCandidate} from "./candidate-component/index";
import {exportExcel,HEADER_ROW_EXPORT_CANDIDATE_TEACHER} from "@/core/filters";
export default {
  name: "manage-candidate-teacher",
  components: {
    DatePicker, FormCandidate, DeleteCandidate, ExtendCandidate, ApproveCandidate
  },
  data() {
    return {
      page: 1,
      last_page: 1,
      query: {
        keyword: '',
        area: [],
        status: [],
        date_range: '',
        center : ''
      },
      listCandidate: [],
      areas: [
        {id:1, name:'Hà Nội và tỉnh'},
        {id:2, name:'TP HCM'},
      ],
      centers: [],
      trainers: [],
      loading: false,
      totalData: '',
      loading_export: false,
      statusDefineCss: {
        0: "badge bg-secondary",
        1: "badge bg-warning text-white",
        2: "badge bg-danger text-white",
        3: "badge bg-successs text-white", 
      },
      infoTeacher: {},
      infoDetail: {},
      statusDefine: {
        0: "Chưa đào tạo",
        1: "Đang đào tạo",
        2: "Bị loại",
        3: "Hoàn thành đào tạo",
      },

      statusTeacher: [
        { id: 0, name: "Chưa đào tạo" },
        { id: 1, name: "Đang đào tạo" },
        { id: 2, name: "Bị loại" },
        { id: 3, name: "Hoàn thành đào tạo" },
      ],

      status_schedule: {
        0: "Chưa học",
        1: "Đang học",
        2: "Đã học",
        3: "Hoàn thành",
      },
      
      status_scheduleCss: {
        0: "bg-secondary",
        1: "bg-warning",
        2: "bg-primary",
        3: "bg-successs",
      },

      action : [
        { title:'Sửa', name: "edit", icon:'fas fa-pen', style:'btn-outline-primary', b_modal: 'handle-candidate-teacher', condition_status: [0,1,2,3]},
        { title:'Gia hạn', name: "extend",icon:'fas fa-cog', style:'btn-outline-info', b_modal: 'handle-extend-candidate' , condition_status: [0,1,2]},
        { title:'Xóa', name: "delete",icon:'fas fa-trash', style:'btn-outline-danger', b_modal: 'handle-delete-candidate' , condition_status: [0]},
        { title:'Duyệt', name: "approve",icon:'fas fa-check', style:'btn-outline-success', b_modal: 'handle-approve-candidate', condition_status: [3], is_teacher : true},
      ],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        },
      },
    }
  },
  mounted() {
    if (this.$route.query.uuid) {
      localStorage.removeItem("uuid-candidate-teacher");
      this.checkApproveCandidateTeacher();
    } else {
      this.getAllCandidate();
    }
    this.getCenters();
    this.getTrainers();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Đào tạo gia nhập giảng viên" }
    ]);
  },
  methods: {
    checkApproveCandidateTeacher() {
      this.$store.dispatch(SHOW_CANDIDATE_TEACHER_BY_UUID, {
        uuid: this.$route.query.uuid
      }).then((res) => {
        if (!res.error) {
          this.$bvModal.show(`handle-approve-candidate`)
          this.handleData(res.data, 'approve')
        }
      }).catch(() => {

      });
    },
    getTrainers() {
      this.$store.dispatch(GET_ALL_TRAINERS).then((data) => {
        if (!data.error) {
          this.trainers = data.data;
        }
      })
    },
    handleData(item, type) {
      this.infoTeacher = item;
      switch (type) {
        case 'edit':
          this.infoTeacher.type = 'edit';
          break;
        case 'delete':
          break;
        case 'extend':
       
          break;
        case 'approve':
          break;
        case 'create':
          this.infoTeacher.type = 'create';
          break;
        default:
          this.infoTeacher = '';
          break;
      }
    },

    // export danh sáchh sách ứng viên giảng viên 
    exportExcel() {
      this.loading_export = true;
      this.$store.dispatch(LIST_CANDIDATE_TEACHER, {
          page: this.page,
          keyword: this.query.keyword,
          area: this.query.area,
          status: this.query.status,
          center: this.query.center,
          date_range: this.query.date_range,
          is_export: 1
        }).then((res) => {
          exportExcel(HEADER_ROW_EXPORT_CANDIDATE_TEACHER, res.data, "danh_sach_ung_vien_giang_vien" + new Date().getTime() / 1000 + ".xlsx",false)
          this.loading_export = false;

        }).catch(() => {
          this.loading_export = false;

        });
    },

    srearchCandidate() {
      this.page = 1;
      this.getAllCandidate();
    },

    getAllCandidate() {
      let check_date = this.query.date_range;
      if (check_date[0] == null || check_date[1] == null) {
        this.query.date_range = '';
      }
      this.loading = true,
        this.$store.dispatch(LIST_CANDIDATE_TEACHER, {
          page: this.page,
          keyword: this.query.keyword,
          area: this.query.area,
          status: this.query.status,
          center: this.query.center,
          date_range: this.query.date_range,
        }).then((res) => {
          this.listCandidate = res.data;
          this.totalData = res.pagination.total;
          this.last_page = res.pagination.last_page;
          this.loading = false;
        }).catch(() => {
          this.loading = false
        });
    },

    getCenters() {
      this.$store.dispatch(GET_CENTERS, {
        is_edutalk : 1,
        status: 1,
        limit: 100000
      }).then((data) => {
        if (!data.error) {
          this.centers = data.data.data;
        }
      })
    },

    searchData() {
      this.getAllCandidate()
    },

    clickCallback(obj) {
      this.page = obj
      this.getAllCandidate()
    },
  }
}
</script>
  
<style>
  #candite-teacher .bg-successs {
    background-color: rgba(0, 128, 13, 1);
  }

  #candite-teacher table th,
  table td {
    padding: 0.8rem;
    text-align: start;
  }

  #candite-teacher .el-dialog__body {
    padding: 0px 20px;
    color: #606266;
    font-size: 14px;
    word-break: break-all;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  @media only screen and (max-width: 1441px){
    #candite-teacher .status-schedule,
    #candite-teacher .area-teacher,
    #candite-teacher .name-teacher {
        width: 100px;
  }
  #candite-teacher .deadline,
  #candite-teacher .name-teacher,
  #candite-teacher .center-teacher {
    width: 200px;
  }
}
</style>
